import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SystemOptionsData } from './system-options.reducer';

export const systemOptionsKey: string = 'systemOptions';

export const selectSystemOptionsState = createFeatureSelector<SystemOptionsData>(systemOptionsKey);

export const getSystemOptions = createSelector(
    selectSystemOptionsState, (state: SystemOptionsData) => state.data,
);

export const getDecimalPlaces = createSelector(
    selectSystemOptionsState, (
        state: SystemOptionsData,
        props?: 'order_product'|'dynamic_tables'|'product_quantity'|'product_weight'
    ) => {
        return (state.data?.getDefaultDecimalPlaces && state.data.getDefaultDecimalPlaces(props));
    },
);

export const getPublicSystemOptions = createSelector(
    selectSystemOptionsState, (state: SystemOptionsData) => state.public,
);

export const getAccountingOptions = createSelector(
  selectSystemOptionsState, (state: SystemOptionsData) => state.accounting,
)

export const getCloseDialogTimestamp = createSelector(
    selectSystemOptionsState, (state: SystemOptionsData) => state.closeDialogTimestamp,
);

export const getNamespaces = createSelector(
  selectSystemOptionsState, (state: SystemOptionsData) => state.namespaces,
);

import { Action, createReducer, on } from '@ngrx/store';

import {
    AccountingOptions,
    CorporateNamespace,
    PublicSystemOptions,
    SystemOptions
} from '../../interfaces/entity-interfaces';
import {
    closeDialog, setAccountingOptions, setNamespaces,
    setPublicSystemOptions,
    setSystemOptions,
    setUpdateStatus,
    updateSystemOptions
} from './system-options.actions';
import { isDefinedNotNull, isNumber } from '../../utils';

const DEFAULT_DECIMAL_PLACES = 2;

const getDecimalPlacesFunc = (systemOptions: SystemOptions, type?: 'order_product'|'dynamic_tables'|'product_quantity'|'product_weight'): number => {
    let value;

    if (type === 'order_product' && isNumber(systemOptions.defaultDecimalPlacesOrderProduct)) {
        value = systemOptions.defaultDecimalPlacesOrderProduct;

    } else if (type === 'dynamic_tables' && isNumber(systemOptions.defaultDecimalPlacesDynamicTables)) {
        value = systemOptions.defaultDecimalPlacesDynamicTables;

    } else if (type === 'product_quantity' && isNumber(systemOptions.defaultDecimalPlacesProductQuantity)) {
        value = systemOptions.defaultDecimalPlacesProductQuantity;

    } else if (type === 'product_weight' && isNumber(systemOptions.defaultDecimalPlacesProductWeight)) {
        value = systemOptions.defaultDecimalPlacesProductWeight;

    } else if (isNumber(systemOptions.defaultDecimalPlaces)) {
        value = systemOptions.defaultDecimalPlaces;

    }

    return isDefinedNotNull(value) ? value : DEFAULT_DECIMAL_PLACES;
};

export interface SystemOptionsData {
    data: SystemOptions;
    public: PublicSystemOptions;
    accounting: AccountingOptions;
    isUpdate: boolean;
    namespaces: CorporateNamespace[];
    closeDialogTimestamp: number;
}

export const initialState: SystemOptionsData = {
    data: {} as SystemOptions,
    public: {} as PublicSystemOptions,
    accounting: {} as AccountingOptions,
    isUpdate: false,
    namespaces: [],
    closeDialogTimestamp: 0,
};

export const systemOptionsReducer = createReducer(
    initialState,
    on(setSystemOptions, (state: SystemOptionsData, action) => {
            const data = action.payload;

            return {
                ...state,
                data: {
                    ...data,
                    getDefaultDecimalPlaces:  (type?: 'order_product'|'dynamic_tables'|'product_quantity'|'product_weight') => data ? getDecimalPlacesFunc(data, type) : 2
                },
            };

        }

    ),
    on(updateSystemOptions, (state: SystemOptionsData, action) => {
        return {
            ...state,
            data: {
                ...state.data,
                [action.payload.key]: action.payload.value, getDefaultDecimalPlaces: (type?: 'order_product'|'dynamic_tables'|'product_quantity'|'product_weight') => {
                    const systemOptions = { ...state.data, [action.payload.key]: action.payload.value };

                    return getDecimalPlacesFunc(systemOptions, type);
                }
            }
        };
    }),
    on(setPublicSystemOptions, (state: SystemOptionsData, action) => ({ ...state, public: action.payload })),
    on(setAccountingOptions, (state: SystemOptionsData, action) => ({ ...state, accounting: action.payload })),
    on(setUpdateStatus, (state: SystemOptionsData, action) => ({ ...state, isUpdate: action.payload })),
    on(closeDialog, (state: SystemOptionsData, action) => ({ ...state, closeDialogTimestamp: Date.now() })),
    on(setNamespaces, (state: SystemOptionsData, action) => ({ ...state, namespaces: action.payload })),
);

export function reducer(state: SystemOptionsData, action: Action): SystemOptionsData {
    return systemOptionsReducer(state, action);
}
